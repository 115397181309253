var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDropzone } from 'react-dropzone';
// @mui
import { Box, Stack, Button, IconButton, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
// assets
import { UploadIllustration } from '../../assets/illustrations';
// components
import Iconify from '../iconify';
import RejectionFiles from './errors/RejectionFiles';
import MultiFilePreview from './preview/MultiFilePreview';
import SingleFilePreview from './preview/SingleFilePreview';
// translation
import { useLocales } from '../../locales';
// ----------------------------------------------------------------------
var StyledDropZone = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        outline: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        position: 'relative',
        padding: theme.spacing(5),
        borderRadius: theme.shape.borderRadius,
        transition: theme.transitions.create('padding'),
        backgroundColor: theme.palette.background.neutral,
        border: "1px dashed ".concat(alpha(theme.palette.grey[500], 0.32)),
        '&:hover': {
            opacity: 0.72,
        },
    });
});
// ----------------------------------------------------------------------
export default function Upload(_a) {
    var disabled = _a.disabled, _b = _a.multiple, multiple = _b === void 0 ? false : _b, previewFileAsFiles = _a.previewFileAsFiles, error = _a.error, helperText = _a.helperText, 
    //
    file = _a.file, onDelete = _a.onDelete, 
    //
    files = _a.files, thumbnail = _a.thumbnail, onUpload = _a.onUpload, onRemove = _a.onRemove, onRemoveAll = _a.onRemoveAll, sx = _a.sx, other = __rest(_a, ["disabled", "multiple", "previewFileAsFiles", "error", "helperText", "file", "onDelete", "files", "thumbnail", "onUpload", "onRemove", "onRemoveAll", "sx"]);
    var _c = useDropzone(__assign({ multiple: multiple, disabled: disabled }, other)), getRootProps = _c.getRootProps, getInputProps = _c.getInputProps, isDragActive = _c.isDragActive, isDragReject = _c.isDragReject, fileRejections = _c.fileRejections;
    var translate = useLocales().translate;
    var hasFile = !!file && !multiple;
    var hasFiles = files && multiple && files.length > 0;
    var isError = isDragReject || !!error;
    return (_jsxs(Box, __assign({ sx: __assign({ width: 1, position: 'relative' }, sx) }, { children: [_jsxs(StyledDropZone, __assign({}, getRootProps(), { sx: __assign(__assign(__assign(__assign({}, (isDragActive && {
                    opacity: 0.72,
                })), (isError && {
                    color: 'error.main',
                    bgcolor: 'error.lighter',
                    borderColor: 'error.light',
                })), (disabled && {
                    opacity: 0.48,
                    pointerEvents: 'none',
                })), (hasFile && {
                    padding: '12% 0',
                })) }, { children: [_jsx("input", __assign({}, getInputProps())), !hasFiles && (_jsx(Placeholder, { sx: __assign({}, (hasFile && {
                            opacity: 0,
                        })) })), hasFile && _jsx(SingleFilePreview, { file: file })] })), helperText && helperText, _jsx(RejectionFiles, { fileRejections: __spreadArray([], fileRejections, true) }), hasFile && onDelete && (_jsx(IconButton, __assign({ size: "small", onClick: onDelete, sx: {
                    top: 16,
                    right: 16,
                    zIndex: 9,
                    position: 'absolute',
                    color: function (theme) { return alpha(theme.palette.common.white, 0.8); },
                    bgcolor: function (theme) { return alpha(theme.palette.grey[900], 0.72); },
                    '&:hover': {
                        bgcolor: function (theme) { return alpha(theme.palette.grey[900], 0.48); },
                    },
                } }, { children: _jsx(Iconify, { icon: "eva:close-fill", width: 18 }) }))), (hasFiles || previewFileAsFiles) && (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ sx: { my: 3 } }, { children: _jsx(MultiFilePreview, { files: files, thumbnail: thumbnail, onRemove: onRemove }) })), _jsxs(Stack, __assign({ direction: "row", justifyContent: "flex-end", spacing: 1.5 }, { children: [onRemoveAll && (_jsx(Button, __assign({ color: "inherit", variant: "outlined", size: "small", onClick: onRemoveAll }, { children: "".concat(translate('Remove all')) }))), onUpload && !previewFileAsFiles && (_jsx(Button, __assign({ size: "small", variant: "contained", onClick: onUpload }, { children: "".concat(translate('Upload files')) })))] }))] }))] })));
}
// ----------------------------------------------------------------------
function Placeholder(_a) {
    var sx = _a.sx, other = __rest(_a, ["sx"]);
    var translate = useLocales().translate;
    return (_jsxs(Stack, __assign({ spacing: 5, alignItems: "center", justifyContent: "center", direction: {
            xs: 'column',
            md: 'row',
        }, sx: __assign({ width: 1, textAlign: {
                xs: 'center',
                md: 'left',
            } }, sx) }, other, { children: [_jsx(UploadIllustration, { sx: { maxWidth: 220 } }), _jsxs("div", { children: [_jsx(Typography, __assign({ gutterBottom: true, variant: "h5" }, { children: "".concat(translate('Drop or Select file')) })), _jsxs(Typography, __assign({ variant: "body2", sx: { color: 'text.secondary' } }, { children: ["".concat(translate('Drop files here or click')), _jsx(Typography, __assign({ variant: "body2", component: "span", sx: {
                                    mx: 0.5,
                                    color: 'primary.main',
                                    textDecoration: 'underline',
                                } }, { children: "".concat(translate('browse')) })), "".concat(translate('thorough your machine'))] }))] })] })));
}
